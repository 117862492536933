import responseHandler from '@utils/responseHandler';
import requestBuilder from '@utils/requestBuilder';

import api from '@constants/api';
import requestsMethods from '@constants/requestsMethods';

export const fetchPublicDocumentUrl = responseHandler(
  async (product, documentType, utmSource) => requestBuilder({
    method: requestsMethods.GET,
    url: api.DOCUMENT_DOWNLOAD(product, documentType, utmSource),
  }),
);

export const getDocumentInfo = responseHandler(async (data) => requestBuilder({
  method: requestsMethods.POST,
  url: api.GET_DOCUMENT_INFO,
  data,
  withAuth: true,
}));

export const initiateSignDocument = responseHandler(async (payload) => requestBuilder({
  method: requestsMethods.POST,
  url: api.GET_DOCUMENT_SIGN_ORDER,
  data: payload,
  withAuth: true,
}));

export const signDocument = responseHandler(async (data, orderRef) => requestBuilder({
  method: requestsMethods.POST,
  url: api.DOCUMENT_VERIFY_SIGNATURE,
  data,
  bankIdOrderRef: orderRef,
  withAuth: true,
}));
