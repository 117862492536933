import {
  call,
  put,
  select,
} from 'redux-saga/effects';

import { selectCachedRequestFactory } from '@redux/reducers/cache';
import { setCachedRequest } from '@redux/actions/cache';

export const cacheKeys = {
  ACCOUNT_GET_LOANS: 'ACCOUNT_GET_LOANS',
  ACCOUNT_GET_INSURANCES: 'ACCOUNT_GET_INSURANCES',
  USER_GET_ME: 'USER_GET_ME',
};

function* cacheSaga(cacheKey, apiCall) {
  const cacheSelector = selectCachedRequestFactory(cacheKey);
  const cachedData = yield select(cacheSelector);
  if (cachedData) return cachedData;

  if (!apiCall) throw new Error(`Missing api logic for ${cacheKey}`);
  const data = yield call(apiCall);
  yield put(setCachedRequest({ key: cacheKey, data }));

  return data;
}

export default cacheSaga;
