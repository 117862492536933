const banks = {
  ALANDSBANKEN: 'alandsbanken',
  DANSKE_BANK: 'danske-bank',
  HANDELSBANKEN: 'handelsbanken',
  ICA: 'ica',
  LANSFORSAKRINGAR: 'lansforsakringar',
  NORDEA: 'nordea',
  SEB: 'seb',
  SKANDIA: 'skandia',
  SPARBANKEN_SYD: 'sparbanken-syd',
  SPARBANKERNA: 'sparbankerna',
  SWEDBANK: 'swedbank',
};

export const tinkBanks = [
  banks.NORDEA,
  banks.SWEDBANK,
  banks.SEB,
  banks.DANSKE_BANK,
  banks.HANDELSBANKEN,
  banks.SPARBANKEN_SYD,
  banks.ICA,
  banks.SKANDIA,
  banks.LANSFORSAKRINGAR,
  banks.SPARBANKERNA,
  banks.ALANDSBANKEN,
];

export const openPaymentsBanks = [
  // banks.SEB,
  banks.HANDELSBANKEN,
  // banks.NORDEA,
  // banks.SWEDBANK,
  // banks.DANSKE_BANK,
];

export const openPaymentsBanksProviderPage = [
  banks.SEB,
  banks.HANDELSBANKEN,
  // banks.NORDEA,
  // banks.SWEDBANK,
  // banks.DANSKE_BANK,
];

export default banks;
