// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bank-login-callback-js": () => import("./../../../src/pages/bank-login-callback.js" /* webpackChunkName: "component---src-pages-bank-login-callback-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mina-sidor-autogiro-ansluta-bank-js": () => import("./../../../src/pages/mina-sidor/autogiro/ansluta-bank.js" /* webpackChunkName: "component---src-pages-mina-sidor-autogiro-ansluta-bank-js" */),
  "component---src-pages-mina-sidor-autogiro-medgivande-js": () => import("./../../../src/pages/mina-sidor/autogiro/medgivande.js" /* webpackChunkName: "component---src-pages-mina-sidor-autogiro-medgivande-js" */),
  "component---src-pages-mina-sidor-autogiro-signera-js": () => import("./../../../src/pages/mina-sidor/autogiro/signera.js" /* webpackChunkName: "component---src-pages-mina-sidor-autogiro-signera-js" */),
  "component---src-pages-mina-sidor-autogiro-valj-bankkonto-js": () => import("./../../../src/pages/mina-sidor/autogiro/valj-bankkonto.js" /* webpackChunkName: "component---src-pages-mina-sidor-autogiro-valj-bankkonto-js" */),
  "component---src-pages-mina-sidor-index-js": () => import("./../../../src/pages/mina-sidor/index.js" /* webpackChunkName: "component---src-pages-mina-sidor-index-js" */),
  "component---src-pages-mina-sidor-mina-forsakringar-detaljer-js": () => import("./../../../src/pages/mina-sidor/mina-forsakringar/detaljer.js" /* webpackChunkName: "component---src-pages-mina-sidor-mina-forsakringar-detaljer-js" */),
  "component---src-pages-mina-sidor-mina-forsakringar-index-js": () => import("./../../../src/pages/mina-sidor/mina-forsakringar/index.js" /* webpackChunkName: "component---src-pages-mina-sidor-mina-forsakringar-index-js" */),
  "component---src-pages-mina-sidor-mina-forsakringar-transaktioner-js": () => import("./../../../src/pages/mina-sidor/mina-forsakringar/transaktioner.js" /* webpackChunkName: "component---src-pages-mina-sidor-mina-forsakringar-transaktioner-js" */),
  "component---src-pages-mina-sidor-mina-lan-betalningsplan-js": () => import("./../../../src/pages/mina-sidor/mina-lan/betalningsplan.js" /* webpackChunkName: "component---src-pages-mina-sidor-mina-lan-betalningsplan-js" */),
  "component---src-pages-mina-sidor-mina-lan-detaljer-js": () => import("./../../../src/pages/mina-sidor/mina-lan/detaljer.js" /* webpackChunkName: "component---src-pages-mina-sidor-mina-lan-detaljer-js" */),
  "component---src-pages-mina-sidor-mina-lan-transaktioner-js": () => import("./../../../src/pages/mina-sidor/mina-lan/transaktioner.js" /* webpackChunkName: "component---src-pages-mina-sidor-mina-lan-transaktioner-js" */)
}

