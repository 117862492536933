import { DateTime } from 'luxon';
import { types } from '@redux/actions/cache';
import { types as authTypes } from '@redux/actions/auth';

const initialState = {};

const CACHE_TIME_OUT = 10;

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CACHED_REQUEST: {
      return {
        ...state,
        [action.key]: {
          data: action.data,
          validUntil: DateTime.now().plus({ minutes: CACHE_TIME_OUT }),
        },
      };
    }
    case types.INVALIDATE_CACHED_REQUEST: {
      return {
        ...state,
        [action.key]: undefined,
      };
    }
    case authTypes.LOGOUT:
      return initialState;
    default: {
      return state;
    }
  }
};

export const selectCachedRequestFactory = (cacheKey) => (state) => {
  const cache = state.cache[cacheKey];
  if (!cache) return undefined;
  const isExpired = DateTime.now() > cache.validUntil;
  if (isExpired) return undefined;
  return cache.data;
};
