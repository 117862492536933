export const types = {
  UPDATE_FIELD: 'moank/signup/UPDATE_FIELD',
  VALIDATE_FIELD: 'moank/signup/VALIDATE_FIELD',
};

export const updateField = (field, value, { min = 0, max = 0 } = {}) => ({
  type: types.UPDATE_FIELD,
  field,
  value,
  min,
  max,
});

export const validateField = (field, valid) => ({ type: types.VALIDATE_FIELD, field, valid });
