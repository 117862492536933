import { all } from 'redux-saga/effects';
import authSaga from './auth';
import bankSaga from './bank';
import documentsSaga from './documents';
import accountsSaga from './accounts';
import settingsSaga from './settings';
import signupSaga from './signup';
import userSaga from './user';

export default function* rootSaga() {
  yield all([
    authSaga(),
    bankSaga(),
    documentsSaga(),
    accountsSaga(),
    settingsSaga(),
    signupSaga(),
    userSaga(),
  ]);
}
