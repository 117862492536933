import responseHandler from '@utils/responseHandler';
import requestBuilder from '@utils/requestBuilder';
import { api, requestsMethods } from '@constants';

export const userFetchSelf = responseHandler(async () => requestBuilder({
  method: requestsMethods.GET,
  url: api.USER_ME,
  withAuth: true,
}));

export const userAuthenticate = responseHandler(async (authData, orderRef) => requestBuilder({
  method: requestsMethods.POST,
  url: api.USER_AUTENTICATE,
  data: authData,
  bankIdOrderRef: orderRef,
}));

export const userCreateBudget = responseHandler(async (userId) => requestBuilder({
  method: requestsMethods.POST,
  url: api.CREATE_BUDGET(userId),
  withAuth: true,
}));
