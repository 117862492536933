import {
  all,
  call,
  put,
  takeEvery,
  take,
  select,
} from 'redux-saga/effects';
import cookies from 'js-cookie';
import { navigate } from 'gatsby';

import cancellableTakeLatest from '@utils/cancellableTakeLatest';
import { urls, bankIdRequests } from '@constants';
import { hideModal, showModal } from '@redux/actions/ui';
import { types as accountActionTypes, getLoans } from '@redux/actions/accounts';
import { invalidateCachedRequest } from '@redux/actions/cache';
import {
  types,
  loginSuccess,
  loginFailure,
} from '@redux/actions/auth';
import bankIdSaga from '@redux/sagas/bankId';
import modalTypes from '@components/modals/types';
import getUrl from '@utils/getUrl';

import { cacheKeys } from './cache';

function* loginSaga(action) {
  const { payload } = action;
  try {
    const params = { action: bankIdRequests.LOGIN, payload };
    yield call(bankIdSaga, params);
    yield put(loginSuccess());
    yield put(hideModal());

    yield put(invalidateCachedRequest(cacheKeys.ACCOUNT_GET_LOANS));
    yield put(getLoans());
    yield take(accountActionTypes.GET_LOANS_SUCCESS);

    const loans = yield select((state) => state.accounts.loans);

    if (!loans || Object.keys(loans).length === 0) {
      yield put(showModal({
        type: modalTypes.ERROR,
        props: {
          title: 'Inloggningen misslyckades',
          message: 'Du har inte något konto hos Moank. Vänligen gör en ansökan.',
        },
      }));
    } else {
      navigate(getUrl(urls.HOME), { replace: true });
    }
  } catch (e) {
    if (e.response && e.response.statusCode === 401) {
      yield put(showModal({
        type: modalTypes.ERROR,
        props: {
          title: 'Inloggningen misslyckades',
          message: e.response.body.message,
        },
      }));
    } else yield put(showModal({ type: modalTypes.ERROR }));

    yield put(loginFailure());
  }
}

function* logoutSaga() {
  yield call(cookies.remove, 'token');
  navigate(getUrl(urls.LANDING), { replace: true });
}

export default function* authSaga() {
  yield all([
    cancellableTakeLatest(
      types.LOGIN_REQUEST,
      accountActionTypes.GET_LOANS_FAILURE,
      loginSaga,
    ),
    takeEvery(types.LOGOUT, logoutSaga),
  ]);
}
