import { types } from '@redux/actions/auth';
import { types as bankTypes } from '@redux/actions/bank';

const initialState = {
  bankIdType: '',
  bankIdLoading: false,
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_BANK_ID_TYPE: {
      return {
        ...state,
        bankIdType: action.payload.bankIdType,
      };
    }
    case types.BANK_ID_REQUEST: {
      return {
        ...state,
        bankIdLoading: true,
      };
    }
    case types.BANK_ID_SUCCESS:
    case types.BANK_ID_FAILURE:
      return initialState;
    case bankTypes.BANK_LOGIN_SESSION_RESET:
      return {
        ...state,
        bankIdType: initialState.bankIdType,
      };
    default: {
      return state;
    }
  }
};
