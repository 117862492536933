export const types = {
  SET_CACHED_REQUEST: 'moank/cache/SET_CACHED_REQUEST',
  INVALIDATE_CACHED_REQUEST: 'moank/cache/INVALIDATE_CACHED_REQUEST',
};

export const setCachedRequest = ({ key, data }) => ({
  type: types.SET_CACHED_REQUEST,
  key,
  data,
});

export const invalidateCachedRequest = (key) => ({
  type: types.INVALIDATE_CACHED_REQUEST,
  key,
});
