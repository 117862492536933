import routes from './routes';

const createUrlsFromRoutes = (root) => (urls, [key, url]) => ({
  ...urls,
  [key]: key === '_baseUrl'
    ? root
    : Object.entries(url).reduce(createUrlsFromRoutes(`${root}/${url._baseUrl}`), {}),
});

const urls = Object.entries(routes).reduce(createUrlsFromRoutes(''), {});

export default urls;
