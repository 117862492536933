const config = require('./global');
const production = require('./production');
const staging = require('./staging');
const local = require('./local');

if (process.env.GATSBY_TARGET_ENV === 'production') {
  Object.assign(config, production);
} else if (process.env.GATSBY_TARGET_ENV === 'staging') {
  Object.assign(config, staging);
} else {
  Object.assign(config, local);
}

module.exports = config;
