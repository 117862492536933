import { createGlobalStyle } from 'styled-components';
import { style } from '@constants';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    font-family: ${style.fontFamily.main};
    font-weight: ${style.fontWeight.regular};
  }
  #app {
    display: flex;
    flex-direction: column;
  }
`;

export default GlobalStyle;
