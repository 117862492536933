export default {
  BANK_ID: 'BANK_ID',
  BANK_LOGIN_FAILED: 'BANK_LOGIN_FAILED',
  ERROR: 'ERROR',
  INFO: 'INFO',
  VALIDATION_FAILED: 'VALIDATION_FAILED',
  MENU: 'MENU',
  COOKIES: 'COOKIES',
  LOGIN: 'LOGIN',
};
