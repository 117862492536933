import request from 'superagent';
import cookies from 'js-cookie';
import { apiKey } from '../../configs';

const requestBuilder = ({
  method,
  url,
  data,
  withAuth,
  bankIdOrderRef,
}) => {
  if (!request[method]) throw new Error('Requested method is invalid');

  const fullUrl = bankIdOrderRef ? `${url}?orderRef=${bankIdOrderRef}` : url;
  const preparedRequest = request[method](fullUrl)
    .set('X-API-Key', apiKey)
    .set('Accept', 'application/json');
  if (withAuth) preparedRequest.set('X-Token', cookies.get('token'));
  if (data) preparedRequest.send(data);
  return preparedRequest;
};

export default requestBuilder;
