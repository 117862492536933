import { types } from '@redux/actions/bank';
import { bankLoginStatuses } from '@constants';

const initialState = {
  loginSession: {
    sessionId: '',
    provider: '',
    status: '',
    message: '',
    token: '', // TODO: Is this usefull ?
    parameters: {},
    error: false,
  },
  reportId: '',
  accounts: [],
  startSessionLoading: false,
  bankAuthenticationLoading: false,
  retrieveDataLoading: false,
  link: '',
  image: '',
  scaApproach: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.BANK_RESET:
    case types.START_BANK_LOGIN_SESSION_FAILURE:
    case types.BANK_AUTHENTICATION_FAILURE:
      return initialState;
    case types.RESTORE_BANK_LOGIN_SESSION:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          ...action.sessionData,
        },
      };
    case types.START_BANK_LOGIN_SESSION:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          provider: action.provider,
          status: bankLoginStatuses.STARTED,
          parameters: action.parameters,
        },
        startSessionLoading: true,
      };
    case types.START_BANK_LOGIN_SESSION_SUCCESS:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          sessionId: action.sessionId,
        },
        scaApproach: action.scaApproach,
        startSessionLoading: false,
      };
    case types.UPDATE_BANK_LOGIN_SESSION:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          status: action.status,
          message: action.message,
          token: action.token,
        },
        image: action.image,
      };
    case types.RETRIEVE_BANK_DATA:
      return {
        ...state,
        loginSession: {
          ...state.loginSession,
          error: false,
        },
        retrieveDataLoading: true,
      };
    case types.RETRIEVE_BANK_DATA_SUCCESS:
      return {
        ...state,
        accounts: action.accounts,
        reportId: action.reportId,
        retrieveDataLoading: false,
      };
    case types.RETRIEVE_BANK_DATA_FAILURE:
      return {
        ...state,
        accounts: initialState.accounts,
        reportId: initialState.reportId,
        loginSession: {
          ...state.loginSession,
          error: true,
        },
        retrieveDataLoading: false,
      };
    case types.BANK_AUTHENTICATION_REQUEST:
      return {
        ...state,
        bankAuthenticationLoading: true,
      };
    case types.BANK_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        link: action.payload.link,
        image: action.payload.image,
        bankAuthenticationLoading: false,
      };
    case types.BANK_LOGIN_SESSION_RESET:
      return {
        ...state,
        loginSession: initialState.loginSession,
        scaApproach: initialState.scaApproach,
        link: initialState.link,
        image: initialState.image,
      };
    default:
      return state;
  }
};

export const loginSessionSelector = (state) => state.bank.loginSession;
