import styled from 'styled-components';
import PropTypes from 'prop-types';

import style from '@constants/style';

export const BORDER_SIZE = '2px';

const sizes = {
  big: {
    padding: {
      vertical: '1.5rem',
      horizontal: '2rem',
    },
    minWidth: '12.5rem',
    fontSize: 'small',
  },
  regular: {
    padding: {
      vertical: '1rem',
      horizontal: '1.25rem',
    },
    minWidth: '12.5rem',
    fontSize: 'small',
  },
  small: {
    padding: {
      vertical: '0.75rem',
      horizontal: '0.75rem',
    },
    minWidth: '5.5rem',
    fontSize: 'smaller',
  },
};

const getTypeColours = ({
  type,
  theme,
  primaryColor,
  secondaryColor,
}) => {
  if (type === 'primary') {
    return {
      borderColour: primaryColor || theme.buttonPrimaryColour,
      buttonColour: primaryColor || theme.buttonPrimaryColour,
      textColour: secondaryColor || theme.buttonSecondaryColour,
    };
  }
  if (type === 'secondary') {
    return {
      borderColour: primaryColor || theme.buttonPrimaryColour,
      buttonColour: 'transparent',
      textColour: primaryColor || theme.buttonPrimaryColour,
    };
  }
  if (type === 'whitePrimary') {
    return {
      borderColour: primaryColor || theme.buttonPrimaryColour,
      buttonColour: style.colours.secondaryLightGray1,
      textColour: primaryColor || theme.buttonPrimaryColour,
    };
  }
  if (type === 'negativePrimary') {
    return {
      borderColour: style.colours.primaryLight,
      buttonColour: style.colours.primaryLight,
      textColour: style.colours.primaryDark,
    };
  }
  if (type === 'hover') {
    return {
      borderColour: primaryColor || theme.buttonPrimaryColour,
      buttonColour: primaryColor || theme.buttonPrimaryColour,
      textColour: secondaryColor || theme.buttonSecondaryColour,
    };
  }
  return {};
};

const StyledButton = styled.button`
  box-sizing: border-box;
  text-align: center;
  text-transform: ${({ noTextTransform }) => (noTextTransform ? 'none' : 'uppercase')};
  letter-spacing: 0.5px;
  font-family: ${style.fontFamily.main};
  vertical-align: middle;
  font-weight: ${style.fontWeight.regular};
  border-width: ${BORDER_SIZE};
  border-style: solid;
  border-radius: 2px;
  text-decoration: none;
  margin: 0;

  ${({ size }) => `
    font-size: ${style.fontSize[sizes[size].fontSize]};
    padding: ${sizes[size].padding.vertical} ${sizes[size].padding.horizontal};
    min-width: calc(${sizes[size].minWidth} - 2 * ${sizes[size].padding.horizontal});
  `}

  cursor: pointer;
  background-color: ${(props) => getTypeColours(props).buttonColour};
  color: ${(props) => getTypeColours(props).textColour};
  border-color: ${(props) => getTypeColours(props).borderColour};

  &:hover {
    background-color: ${(props) => getTypeColours({ ...props, type: props.hovertype }).buttonColour};
    color: ${(props) => getTypeColours({ ...props, type: props.hovertype }).textColour};
    border-color: ${(props) => getTypeColours({ ...props, type: props.hovertype }).borderColour};
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.2);
  }

  &:disabled {
    ${(props) => (props.softDisabled ? `
      background-color: ${getTypeColours(props).buttonColour};
      border-color: ${getTypeColours(props).borderColour};
      color: ${getTypeColours(props).textColour};
      cursor: default;
    ` : `
      background-color: ${style.colours.secondaryLightGray5};
      border-color: ${style.colours.secondaryLightGray5};
      color: ${style.colours.secondaryDarkGray2};
      cursor: not-allowed;
    `)}

    box-shadow: none;
  }

  ${style.focusVisibleMixin}
`;

StyledButton.propTypes = {
  size: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  hovertype: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default StyledButton;
