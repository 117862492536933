import { apiUrl } from '../../configs';

const api = {
  AUTHORIZATION_IDENTIFICATION: `${apiUrl}/authorization/identification`,
  FETCH_SIGN_ORDER: (applicantId, applicationId) => `${apiUrl}/application/${applicationId}/fetch-sign-order/${applicantId}`,
  SIGNER_DATA: (applicantId, applicationId) => `${apiUrl}/application/${applicationId}/fetch/${applicantId}`,
  USER_LOANS: `${apiUrl}/account/user-loans`,
  USER_INSURANCES: `${apiUrl}/account/user-insurances`,
  ACCOUNT_AGREEMENT: (id) => `${apiUrl}/account/${id}/download`,
  ACCOUNT_INVOICE: (id, invoiceId) => `${apiUrl}/account/${id}/invoices/${invoiceId}/download`,
  UPDATE_BANK_ACCOUNT_PAYIN: (accountId) => `${apiUrl}/account/${accountId}/connect-autogiro`,
  USER_ME: `${apiUrl}/user/me`,
  USER_AUTENTICATE: `${apiUrl}/user/authenticate`,
  CREATE_BUDGET: (id) => `${apiUrl}/user/${id}/budget`,
  SETTINGS_PUBLIC: `${apiUrl}/settings/public`,
  BANK_START_LOGIN_SESSION: `${apiUrl}/bank/startLoginSession`,
  BANK_CHECK_LOGIN_SESSION: (sessionId) => `${apiUrl}/bank/checkLoginSession/${sessionId}`,
  BANK_RETRIEVE_DATA: `${apiUrl}/bank/retrieveData`,
  DOCUMENT_DOWNLOAD: (accountType, documentType, utmSource) => `${apiUrl}/publicDocuments/${accountType}/${documentType}?utmSource=${utmSource}`,
  GET_DOCUMENT_INFO: `${apiUrl}/document`,
  GET_DOCUMENT_SIGN_ORDER: `${apiUrl}/document/sign-order`,
  DOCUMENT_VERIFY_SIGNATURE: `${apiUrl}/document/verify-signature`,
  BANK_AUTHENTICATION_LINK: `${apiUrl}/bank/authentication-link`,
  ANNUAL_TAX_REPORT: (id) => `${apiUrl}/download/user/${id}/annual-tax-report`,
  SIGN_LINK: `${apiUrl}/authorization/signRequest`,
};

export default api;
