import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux';
import {
  PERSIST,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import createActionBuffer from 'redux-action-buffer';
import createSagaMiddleware from 'redux-saga';

import * as reducers from '@redux/reducers';
import rootSaga from '@redux/sagas';

import { cookiesNames, getCookie } from '@utils/cookies';

const VERSION = 5;

const persistReducerConfig = {
  key: 'root',
  storage: storageSession,
  version: VERSION,
  migrate: (state) => {
    if (!state || state._persist.version !== VERSION) {
      return Promise.resolve({});
    }
    return Promise.resolve(state);
  },
};

const persistStoreConfigs = {
  manualPersist: true,
};

const reduxElements = {
  currentPersistor: null,
};

export const setupStore = () => {
  const hasExperienceCookie = getCookie(cookiesNames.EXPERIENCE_COOKIES_ALLOWED);

  const {
    currentPersistor,
  } = reduxElements;

  if (hasExperienceCookie) {
    currentPersistor.persist();
  } else {
    currentPersistor.pause();
    currentPersistor.purge();
  }
};

const breaker = (action) => {
  const hasExperienceCookie = getCookie(cookiesNames.EXPERIENCE_COOKIES_ALLOWED);
  if (hasExperienceCookie) return action.type === REHYDRATE;
  return true;
};

const createCustomStore = () => {
  const composeEnhancers = process.env.NODE_ENV !== 'production'
    ? (global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)
    : compose;

  const sagaMiddleware = createSagaMiddleware();
  const actionBufferMiddleware = createActionBuffer({ passthrough: [PERSIST], breaker });
  const middlewares = [sagaMiddleware, actionBufferMiddleware];

  const store = createStore(
    persistReducer(persistReducerConfig, combineReducers(reducers)),
    {},
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store, persistStoreConfigs);

  reduxElements.currentPersistor = persistor;

  sagaMiddleware.run(rootSaga);

  setupStore();

  return store;
};

export default createCustomStore;
