export const types = {
  UPDATE_BANK_ACCOUNT_PAYIN: 'moank/account/UPDATE_BANK_ACCOUNT_PAYIN',
  UPDATE_BANK_ACCOUNT_PAYIN_SUCCESS: 'moank/account/UPDATE_BANK_ACCOUNT_PAYIN_SUCCESS',
  UPDATE_BANK_ACCOUNT_PAYIN_FAILURE: 'moank/account/UPDATE_BANK_ACCOUNT_PAYIN_FAILURE',
  GET_LOANS_REQUEST: 'moank/account/GET_LOANS_REQUEST',
  GET_LOANS_SUCCESS: 'moank/account/GET_LOANS_SUCCESS',
  GET_LOANS_FAILURE: 'moank/account/GET_LOANS_FAILURE',
  GET_INSURANCES_REQUEST: 'moank/account/GET_INSURANCES_REQUEST',
  GET_INSURANCES_SUCCESS: 'moank/account/GET_INSURANCES_SUCCESS',
  GET_INSURANCES_FAILURE: 'moank/account/GET_INSURANCES_FAILURE',
};

export const getLoans = () => ({ type: types.GET_LOANS_REQUEST });
export const getLoansSuccess = (loans) => ({
  type: types.GET_LOANS_SUCCESS,
  loans,
});
export const getLoansFailure = () => ({ type: types.GET_LOANS_FAILURE });

export const getInsurances = () => ({ type: types.GET_INSURANCES_REQUEST });
export const getInsurancesSuccess = (insurances) => ({
  type: types.GET_INSURANCES_SUCCESS,
  insurances,
});
export const getInsurancesFailure = () => ({ type: types.GET_INSURANCES_FAILURE });

export const updateBankAccountPayin = (accountId, newBankAccountIdPayin) => ({
  type: types.UPDATE_BANK_ACCOUNT_PAYIN,
  payload: {
    accountId,
    newBankAccountIdPayin,
  },
});
export const updateBankAccountPayinSuccess = (accountId, bankAccountIdPayin) => ({
  type: types.UPDATE_BANK_ACCOUNT_PAYIN_SUCCESS,
  payload: { accountId, bankAccountIdPayin },
});
export const updateBankAccountPayinFailure = (accountId) => ({
  type: types.UPDATE_BANK_ACCOUNT_PAYIN_FAILURE,
  payload: { accountId },
});
