module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Moank","short_name":"Moank","start_url":"/","background_color":"#fff","theme_color":"#fff","lang":"sv-SE","icon":"src/assets/img/moank_favicon.svg","icon_options":{"purpose":"any maskable"},"display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5498a51edf1ad146cc3e212efe2417c0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100,"duration":500},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
