const toFixedFix = (n, prec) => {
  // Fix for IE parseFloat(0.55).toFixed(0) = 0;
  const k = 10 ** prec;
  return Math.round(n * k) / k;
};

const numberFormat = (number, decimals = 2, options = {}) => {
  const n = !Number.isFinite(+number) ? 0 : +number;
  const prec = !Number.isFinite(+decimals) ? 0 : Math.abs(decimals);
  const sep = options.thousandSep || '\xa0';
  const dec = options.decPoint || ',';
  const s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  if ((s[1] || '').length < prec) {
    s[1] = s[1] || '';
    s[1] += new Array((prec - s[1].length) + 1).join('0');
  }
  let formattedAmount = s.join(dec);
  if (options.unit) formattedAmount = `${formattedAmount}\xa0${options.unit}`;
  if (options.withSign) formattedAmount = number >= 0 ? `+${formattedAmount}` : formattedAmount;
  return formattedAmount;
};

export default numberFormat;
