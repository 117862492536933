import React, { Component } from 'react';
import PropTypes from 'prop-types';
import style from '@constants/style';
import { Provider } from './MediaQueryContext';

const getScreenType = () => {
  const width = global.innerWidth;
  const { screenType } = Object.keys(style.minSizes).reduce((current, key) => {
    if (style.minSizes[key] > current.minSize && width >= style.minSizes[key]) {
      return {
        screenType: key,
        minSize: style.minSizes[key],
      };
    }
    return current;
  }, { minSize: -Infinity });
  return screenType;
};

class MediaQueryProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenType: props.screenType || getScreenType(),
    };
    this.dimensionHandler = this.dimensionHandler.bind(this);
  }

  componentDidMount() {
    const { screenType } = this.props;
    if (!screenType) {
      global.addEventListener('resize', this.dimensionHandler);
    }
  }

  componentWillUnmount() {
    const { screenType } = this.props;
    if (!screenType) {
      global.removeEventListener('resize', this.dimensionHandler);
    }
  }

  dimensionHandler() {
    const newScreenType = getScreenType();
    const { screenType } = this.state;
    if (newScreenType !== screenType) this.setState({ screenType: newScreenType });
  }

  render() {
    const { screenType } = this.state;
    const { children } = this.props;
    return <Provider value={screenType}>{children}</Provider>;
  }
}

MediaQueryProvider.propTypes = {
  screenType: PropTypes.string,
  children: PropTypes.node.isRequired,
};

MediaQueryProvider.defaultProps = {
  screenType: undefined,
};

export default MediaQueryProvider;
