import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import styled, { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Button from '@components/elements/Button';
import Loader from '@components/elements/Loader';
import Headline from '@components/elements/Headline';
import Paragraph from '@components/elements/Paragraph';
import FlexBox from '@components/containers/FlexBox';
import Anchor from '@components/elements/Anchor';
import { externals, info } from '@constants';
import moankTheme from '@constants/moankTheme';
import GlobalStyle from '@components/GlobalStyles';

const reloadHandler = (e) => {
  e.preventDefault();
  global.location.reload(true);
};

const StyledMessageSection = styled('section')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-grow: 1;
  padding: 0 2rem;
  max-width: 30rem;
  margin: auto;
`;

const ERROR_RETRY_PARAMS = 'error_retry';

class PageErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false, pendingCheck: true };
  }

  componentDidCatch(error, errorInfo) {
    const searchParams = queryString.parse(global.location.search);

    if (/^Loading chunk .* failed\./.test(error.message) && !searchParams[ERROR_RETRY_PARAMS]) {
      const url = new URL(global.location);
      url.searchParams.set(ERROR_RETRY_PARAMS, true);
      global.location.href = url.toString();
      this.setState({ pendingCheck: false });
    } else {
      this.setState({ pendingCheck: false });
      Sentry.withScope((scope) => {
        scope.setExtra('errorInfo', errorInfo);
        Sentry.captureException(error);
      });
    }
  }

  render() {
    const { hasError, pendingCheck } = this.state;
    const { children } = this.props;
    if (hasError) {
      if (pendingCheck) {
        return (
          <StyledMessageSection>
            <Loader />
          </StyledMessageSection>
        );
      }
      return (
        <ThemeProvider theme={moankTheme}>
          <GlobalStyle />
          <StyledMessageSection>
            <Headline as="h1" textAlign="center">Något gick fel</Headline>
            <Paragraph textAlign="center">Sidan kunde inte visas. Var vänlig försök igen. Om felet kvarstår, kontakta oss på <Anchor href={externals.MAIL_TO_MOANK}>{info.CUSTOMER_SERVICE_EMAIL}</Anchor>, <Anchor href={externals.CALL_TO_MOANK}>{info.CUSTOMER_SERVICE_NUMBER}</Anchor> eller i chatten.</Paragraph>
            <FlexBox><Button onClick={reloadHandler}>Försök igen</Button></FlexBox>
          </StyledMessageSection>
        </ThemeProvider>
      );
    }

    return children;
  }
}

PageErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageErrorBoundary;
