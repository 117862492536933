export const types = {
  SHOW_MODAL: 'moank/ui/SHOW_MODAL',
  HIDE_MODAL: 'moank/ui/HIDE_MODAL',
  CLOSE_BROWSER_NOTICE: 'moank/ui/CLOSE_BROWSER_NOTICE',
  CLOSE_INFORMATION_NOTICE: 'moank/ui/CLOSE_INFORMATION_NOTICE',
};

// Actions
export const showModal = (modalData) => {
  const modal = { type: modalData.type || modalData };
  if (typeof modalData !== 'string') {
    modal.options = modalData.options;
    modal.props = modalData.props;
  }
  return {
    type: types.SHOW_MODAL,
    modal,
  };
};
export const hideModal = () => ({ type: types.HIDE_MODAL });
export const closeBrowserNotice = () => ({ type: types.CLOSE_BROWSER_NOTICE });
export const closeInformationNotice = () => ({ type: types.CLOSE_INFORMATION_NOTICE });
