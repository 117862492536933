import * as Sentry from '@sentry/browser';

const responseHandler = (requestMethod) => async (...args) => {
  try {
    const res = await requestMethod(...args);
    return res.body;
  } catch (e) {
    const statusCode = e.response && e.response.statusCode;
    if (statusCode && (statusCode < 200 || statusCode >= 500)) {
      Sentry.withScope((scope) => {
        scope.setExtra('response', e.response);
        scope.setExtra('message', e.message);
        Sentry.captureException(e);
      });
    }
    throw e;
  }
};

export default responseHandler;
