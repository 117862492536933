import {
  call,
  select,
  delay,
  put,
} from 'redux-saga/effects';
import cookies from 'js-cookie';

import {
  initiateIdentification,
} from '@apiClient/authorization';
import {
  initiateSignDocument,
  signDocument,
} from '@apiClient/document';
import {
  userAuthenticate,
} from '@apiClient/user';
import { bankIdTypes, bankIdRequests } from '@constants';
import { bankId, bankIdSuccess, bankIdFailure } from '@redux/actions/auth';

const getBankIdType = (state) => state.auth.bankIdType;

const bankIdCollectMethods = {
  [bankIdRequests.LOGIN]: undefined,
  [bankIdRequests.SIGN_DOCUMENT]: signDocument,
};

const bankIdInitialisationMethods = {
  [bankIdRequests.LOGIN]: initiateIdentification,
  [bankIdRequests.SIGN_DOCUMENT]: initiateSignDocument,
};

function* bankIdIdentify(token, action, payload, signingPartyType) {
  let res = { pending: true };
  while (res.pending) {
    yield delay(3000);
    try {
      res = yield call(userAuthenticate, {}, token.orderRef);
    } catch (e) {
      if (!e.message.startsWith('Request has been terminated')) throw e;
    }
  }
  const { token: jwt } = res.data;
  if (jwt) {
    cookies.set('token', jwt, { secure: process.env.NODE_ENV === 'production', SameSite: 'Lax' });
  }

  if (bankIdCollectMethods[action]) {
    res = yield call(
      bankIdCollectMethods[action],
      { ...payload, signingPartyType },
    );
    return res;
  }
  return {};
}

function* bankIdSign(token, action, payload, signingPartyType) {
  let res = { pending: true };
  while (res.pending) {
    yield delay(3000);
    try {
      res = yield call(
        bankIdCollectMethods[action],
        { ...payload, signingPartyType },
        token.orderRef,
      );
    } catch (e) {
      if (!e.message.startsWith('Request has been terminated')) throw e;
    }
  }
  return res;
}

const handleBankIdMethods = {
  [bankIdRequests.LOGIN]: bankIdIdentify,
  [bankIdRequests.SIGN_DOCUMENT]: bankIdSign,
};

export default function* bankIdSaga({
  payload,
  action,
}) {
  try {
    const signingPartyType = yield select((state) => state.signup.signingPartyType);
    const initialisationMethod = bankIdInitialisationMethods[action];
    if (!initialisationMethod) throw new Error(`Invalid BankID request. Received ${action}, expected ${Object.values(bankIdRequests).join(', ')}`);

    yield put(bankId());
    const token = yield call(initialisationMethod, { ...payload, signingPartyType });
    const bankIdType = yield select(getBankIdType);
    if (bankIdType === bankIdTypes.SAME_DEVICE) {
      global.location = `bankid:///?autostarttoken=${token.autoStartToken}&redirect=null`;
    }
    const res = yield handleBankIdMethods[action](token, action, payload, signingPartyType);

    yield put(bankIdSuccess());
    return res.data;
  } catch (e) {
    yield put(bankIdFailure());
    throw e;
  }
}
