import responseHandler from '@utils/responseHandler';
import requestBuilder from '@utils/requestBuilder';

import api from '@constants/api';
import requestsMethods from '@constants/requestsMethods';

export const getLoans = responseHandler(async () => requestBuilder({
  method: requestsMethods.GET,
  url: api.USER_LOANS,
  withAuth: true,
}));

export const getInsurances = responseHandler(async () => requestBuilder({
  method: requestsMethods.GET,
  url: api.USER_INSURANCES,
  withAuth: true,
}));

export const fetchAccountAgreement = responseHandler(async (id) => requestBuilder({
  method: requestsMethods.GET,
  url: api.ACCOUNT_AGREEMENT(id),
  withAuth: true,
}));

export const fetchAnnualTaxReport = responseHandler(async (url) => requestBuilder({
  method: requestsMethods.POST,
  url: api.SIGN_LINK,
  data: { url },
  withAuth: true,
}));

export const fetchInvoice = responseHandler(async (id, invoiceId) => requestBuilder({
  method: requestsMethods.GET,
  url: api.ACCOUNT_INVOICE(id, invoiceId),
  withAuth: true,
}));

export const updateBankAccountPayin = responseHandler(async (
  accountId,
  newBankAccountIdPayin,
) => requestBuilder({
  method: requestsMethods.POST,
  url: api.UPDATE_BANK_ACCOUNT_PAYIN(accountId),
  data: { bankAccountIdPayin: newBankAccountIdPayin },
  withAuth: true,
}));
