import { types } from '@redux/actions/user';
import { types as authTypes } from '@redux/actions/auth';

const initialState = {
  active: null,
  bankDetails: {
    bankAccounts: [],
  },
  createdAt: null,
  economicDetails: {
    kids: null,
    monthlyHousing: null,
    monthlyIncome: null,
    monthlyLoans: null,
    occupation: '',
    reasonForLoan: '',
  },
  email: '',
  firstName: '',
  id: null,
  lastName: '',
  phone: '',
  postcode: '',
  roles: [],
  socialSecurityNumber: '',
  streetAddress: '',
  town: '',
  isExtensionAllowed: null,
  error: null,
  loading: false,
  budget: {
    isLoading: false,
    sheetUrl: '',
  },
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_USER_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.FETCH_USER_SUCCESS: {
      return {
        ...state,
        ...action.user,
        loading: false,
      };
    }
    case types.FETCH_USER_FAILURE: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case authTypes.LOGOUT: {
      return initialState;
    }
    case types.SET_SSN: {
      return {
        ...state,
        socialSecurityNumber: action.ssn,
      };
    }
    case types.CREATE_BUDGET_REQUEST: {
      return {
        ...state,
        budget: {
          ...state.budget,
          isLoading: true,
        },
      };
    }
    case types.CREATE_BUDGET_SUCCESS: {
      return {
        ...state,
        budget: {
          ...action.payload,
          isLoading: false,
        },
      };
    }
    default: {
      return state;
    }
  }
};
