import responseHandler from '@utils/responseHandler';
import requestBuilder from '@utils/requestBuilder';
import { api, requestsMethods } from '@constants';

export const startBankLoginSession = responseHandler(async (
  provider,
  parameters,
) => requestBuilder({
  method: requestsMethods.POST,
  url: api.BANK_START_LOGIN_SESSION,
  data: { provider, parameters },
}));

export const checkBankLoginSession = responseHandler(
  async (sessionId, bankIdType) => requestBuilder({
    method: requestsMethods.POST,
    url: api.BANK_CHECK_LOGIN_SESSION(sessionId),
    data: { bankIdType },
  }),
);

export const retrieveBankData = responseHandler(async (data) => requestBuilder({
  method: requestsMethods.POST,
  url: api.BANK_RETRIEVE_DATA,
  data,
}));

export const fetchBankAuthenticationLink = responseHandler(async (data) => requestBuilder({
  method: requestsMethods.POST,
  url: api.BANK_AUTHENTICATION_LINK,
  data,
}));
