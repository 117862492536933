import responseHandler from '@utils/responseHandler';
import requestBuilder from '@utils/requestBuilder';
import { api, requestsMethods } from '@constants';

// eslint-disable-next-line import/prefer-default-export
export const initiateIdentification = responseHandler(async (payload) => requestBuilder({
  method: requestsMethods.POST,
  url: api.AUTHORIZATION_IDENTIFICATION,
  data: payload,
}));
