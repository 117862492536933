import style from './style';

const moankTheme = {
  primaryColour: style.colours.primaryDark,
  secondaryColour: style.colours.secondaryDarkGray4,
  buttonPrimaryColour: style.colours.secondaryDarkGray1,
  buttonSecondaryColour: style.colours.secondaryLightGray1,
  sliderColour: style.colours.secondaryDarkGray4,
  sliderHandlerColour: style.colours.secondaryDarkGray1,
  background: style.colours.primaryLight,
};

export default moankTheme;
