export const types = {
  BANK_ID_REQUEST: 'moank/auth/BANK_ID_REQUEST',
  BANK_ID_SUCCESS: 'moank/auth/BANK_ID_SUCCESS',
  BANK_ID_FAILURE: 'moank/auth/BANK_ID_FAILURE',
  LOGIN_REQUEST: 'moank/auth/LOGIN_REQUEST',
  LOGIN_SUCCESS: 'moank/auth/LOGIN_SUCCESS',
  LOGIN_FAILURE: 'moank/auth/LOGIN_FAILURE',
  LOGOUT: 'moank/auth/LOGOUT',
  SELECT_BANK_ID_TYPE: 'moank/auth/SELECT_BANK_ID_TYPE',
};

export const bankId = () => ({ type: types.BANK_ID_REQUEST });
export const bankIdSuccess = () => ({ type: types.BANK_ID_SUCCESS });
export const bankIdFailure = () => ({ type: types.BANK_ID_FAILURE });
export const login = (payload) => ({ type: types.LOGIN_REQUEST, payload });
export const loginSuccess = () => ({ type: types.LOGIN_SUCCESS });
export const loginFailure = () => ({ type: types.LOGIN_FAILURE });
export const logout = () => ({ type: types.LOGOUT });
export const selectBankIdType = (bankIdType) => ({
  type: types.SELECT_BANK_ID_TYPE,
  payload: { bankIdType },
});
