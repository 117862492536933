export const types = {
  SET_SSN: 'moank/user/SET_SSN',
  FETCH_USER_REQUEST: 'moank/user/FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS: 'moank/user/FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE: 'moank/user/FETCH_USER_FAILURE',
  CREATE_BUDGET_REQUEST: 'moank/user/CREATE_BUDGET_REQUEST',
  CREATE_BUDGET_SUCCESS: 'moank/user/CREATE_BUDGET_SUCCESS',
  CREATE_BUDGET_FAILURE: 'moank/user/CREATE_BUDGET_FAILURE',
};

export const setSocialSecurityNumber = (ssn) => ({ type: types.SET_SSN, ssn });
export const fetchUser = () => ({ type: types.FETCH_USER_REQUEST });
export const fetchUserSuccess = (user) => ({ type: types.FETCH_USER_SUCCESS, user });
export const fetchUserFailure = (error) => ({ type: types.FETCH_USER_FAILURE, error });
export const createBudget = (userId) => ({ type: types.CREATE_BUDGET_REQUEST, userId });
export const createBudgetSuccess = (payload) => ({ type: types.CREATE_BUDGET_SUCCESS, payload });
export const createBudgetFailure = () => ({ type: types.CREATE_BUDGET_FAILURE });
