import React from 'react';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import TagManager from 'react-gtm-module';
import smoothscroll from 'smoothscroll-polyfill';
import 'core-js/modules/es.string.match-all'; // Needed for gbimage-bridge
import { Settings } from 'luxon';

import Root from '@components/Root';
import LiveAgent from '@components/containers/LiveAgent';

import config from './configs';

export const wrapRootElement = Root;

export const wrapPageElement = ({ element }) => (
  <LiveAgent
    scriptId="la_x2s6df8d"
    buttonId="l4dubw0x"
  >
    {element}
  </LiveAgent>
);

export const onClientEntry = () => {
  Settings.defaultLocale = 'sv';
  Sentry.init({
    dsn: config.sentryUrl,
    environment: config.environment,
    release: config.sentryRelease,
    integrations: [new Integrations.ExtraErrorData({ depth: 10 })],
    ignoreErrors: [
      'Object Not Found Matching Id', // Microsoft crawler errors, see https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    ],
    normalizeDepth: 11, // see https://github.com/getsentry/sentry-javascript/issues/2539#issuecomment-616638746
  });
  TagManager.initialize({ gtmId: config.gtmId });
  smoothscroll.polyfill();
};
