import { types } from '@redux/actions/settings';

const initialState = {
  choices: {
    activeBanks: [],
    children: [],
    companySizes: [],
    homeTypes: [],
    occupations: [],
    paySystems: [],
    activeReasonsForLoan: [],
    brandAwarenessReasonsDirect: [],
    brandAwarenessReasonsBroker: [],
    countries: [],
  },
  constants: {
    signableDocumentTypes: {},
    utmMediums: {},
    applicationStatuses: {},
    providers: {},
    products: {},
    accountStatuses: {},
    publicDocumentFileNames: {},
    documentTypes: {},
  },
  utils: {
    getProductByType: undefined,
  },
  mtpLevels: [],
};

// Reducer
export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.settings,
      };
    }
    default: {
      return state;
    }
  }
};
