import styled from 'styled-components';
import style from '@constants/style';
import { rgba } from 'polished';

const getFullWidth = ({ padding, paddingRight, paddingLeft }) => (padding
  ? `calc(100% - 2 * ${style.padding[padding]})`
  : `calc(100% - ${style.padding[paddingRight]} - ${style.padding[paddingLeft]})`);

export const StyledFlexBox = styled('div')`
  display: flex;
  position: ${(props) => props.position};
  ${(props) => (props.bottom ? `bottom: ${props.bottom};` : '')}
  ${(props) => (props.top ? `top: ${props.top};` : '')}
  flex-direction: ${(props) => props.flexDirection};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  align-self: ${(props) => props.alignSelf};
  flex-wrap: ${(props) => props.flexWrap};
  align-content: ${(props) => props.alignContent};
  flex-grow: ${(props) => props.flexGrow};
  flex-shrink: ${(props) => props.flexShrink};
  gap: ${(props) => style.margin[props.gap]};
  margin-top: ${(props) => style.margin[props.margin] || style.margin[props.marginTop]};
  margin-bottom: ${(props) => style.margin[props.margin] || style.margin[props.marginBottom]};
  margin-left: ${(props) => style.margin[props.margin] || style.margin[props.marginLeft]};
  margin-right: ${(props) => style.margin[props.margin] || style.margin[props.marginRight]};
  padding-top: ${(props) => style.padding[props.padding] || style.padding[props.paddingTop]};
  padding-bottom: ${(props) => style.padding[props.padding] || style.padding[props.paddingBottom]};
  padding-left: ${(props) => style.padding[props.padding] || style.padding[props.paddingLeft]};
  padding-right: ${(props) => style.padding[props.padding] || style.padding[props.paddingRight]};
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
  ${(props) => (props.maxWidthLargeScreen ? style.media[props.breakSize]`max-width: ${props.maxWidthLargeScreen};` : '')}
  ${(props) => (props.minWidth ? `min-width: ${props.minWidth};` : '')}
  ${(props) => (props.minWidthLargeScreen ? style.media[props.breakSize]`min-width: ${props.minWidthLargeScreen};` : '')}
  ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
  ${(props) => (props.minHeightLargeScreen ? style.media[props.breakSize]`min-height: ${props.minHeightLargeScreen};` : '')}
  ${(props) => (props.maxHeight ? `max-height: ${props.maxHeight};` : '')}
  ${(props) => (props.maxHeightLargeScreen ? style.media[props.breakSize]`max-height: ${props.maxHeightLargeScreen};` : '')}
  ${(props) => (props.opacity ? `opacity: ${props.opacity};` : '')}
  border-top-style: ${(props) => props.borderStyle || props.borderTopStyle};
  border-top-width: ${(props) => props.borderWidth || style.borderWidths[props.borderTopWidth]};
  border-top-color: ${(props) => props.borderColor || props.borderTopColor};
  border-right-style: ${(props) => props.borderStyle || props.borderRightStyle};
  border-right-width: ${(props) => props.borderWidth || style.borderWidths[props.borderRightWidth]};
  border-right-color: ${(props) => props.borderColor || props.borderRightColor};
  border-bottom-style: ${(props) => props.borderStyle || props.borderBottomStyle};
  border-bottom-width: ${(props) => props.borderWidth || style.borderWidths[props.borderBottomWidth]};
  border-bottom-color: ${(props) => props.borderColor || props.borderBottomColor};
  border-left-style: ${(props) => props.borderStyle || props.borderLeftStyle};
  border-left-width: ${(props) => props.borderWidth || style.borderWidths[props.borderLeftWidth]};
  border-left-color: ${(props) => props.borderColor || props.borderLeftColor};
  border-radius: ${(props) => props.borderRadius};
  ${(props) => (props.cursor ? `cursor: ${props.cursor};` : '')}
  ${(props) => (props.backgroundColor ? `background-color: ${rgba(style.colours[props.backgroundColor], props.backgroundOpacity)};` : '')};
  ${(props) => (props.fullWidth ? `width: ${getFullWidth(props)}` : '')};
  ${(props) => (props.overflow ? `overflow: ${props.overflow};` : '')}

  ${(props) => props.breakSize && style.media[props.breakSize]`
    margin-top: ${style.margin[props.marginBreakSize] || style.margin[props.marginTopBreakSize] || style.margin[props.margin] || style.margin[props.marginTop]};
    margin-bottom: ${style.margin[props.marginBreakSize] || style.margin[props.marginBottomBreakSize] || style.margin[props.margin] || style.margin[props.marginBottom]};
    margin-left: ${style.margin[props.marginBreakSize] || style.margin[props.marginLeftBreakSize] || style.margin[props.margin] || style.margin[props.marginLeft]};
    margin-right: ${style.margin[props.marginBreakSize] || style.margin[props.marginRightBreakSize] || style.margin[props.margin] || style.margin[props.marginRight]};
  `}
`;

StyledFlexBox.defaultProps = {
  position: 'static',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  alignSelf: 'auto',
  flexWrap: 'nowrap',
  alignContent: 'stretch',
  flexGrow: '0',
  flexShrink: '1',
  gap: 'none',
  marginTop: 'none',
  marginBottom: 'none',
  marginLeft: 'none',
  marginRight: 'none',
  margin: undefined,
  paddingTop: 'none',
  paddingBottom: 'none',
  paddingLeft: 'none',
  paddingRight: 'none',
  padding: undefined,
  maxWidth: undefined,
  maxWidthLargeScreen: undefined,
  minWidth: undefined,
  minWidthLargeScreen: undefined,
  minHeight: undefined,
  minHeightLargeScreen: undefined,
  maxHeight: undefined,
  maxHeightLargeScreen: undefined,
  opacity: undefined,
  borderBottomStyle: 'none',
  borderBottomWidth: 'regular',
  borderBottomColor: style.colours.secondaryDarkGray1,
  borderRadius: '0',
  backgroundColor: undefined,
  backgroundOpacity: 1,
  cursor: undefined,
  marginTopBreakSize: undefined,
  marginBottomBreakSize: undefined,
  marginLeftBreakSize: undefined,
  marginRightBreakSize: undefined,
  marginBreakSize: undefined,
  breakSize: undefined,
  overflow: undefined,
};

const FlexBox = StyledFlexBox;

export default FlexBox;
