export const types = {
  START_BANK_LOGIN_SESSION: 'moank/bank/START_BANK_LOGIN_SESSION',
  START_BANK_LOGIN_SESSION_SUCCESS: 'moank/bank/START_BANK_LOGIN_SESSION_SUCCESS',
  START_BANK_LOGIN_SESSION_FAILURE: 'moank/bank/START_BANK_LOGIN_SESSION_FAILURE',
  START_BANK_LOGIN_POLLING: 'moank/bank/START_BANK_LOGIN_POLLING',
  UPDATE_BANK_LOGIN_SESSION: 'moank/bank/UPDATE_BANK_LOGIN_SESSION',
  RETRIEVE_BANK_DATA: 'moank/bank/RETRIEVE_BANK_DATA',
  RETRIEVE_BANK_DATA_SUCCESS: 'moank/bank/RETRIEVE_BANK_DATA_SUCCESS',
  RETRIEVE_BANK_DATA_FAILURE: 'moank/bank/RETRIEVE_BANK_DATA_FAILURE',
  BANK_RESET: 'moank/bank/BANK_RESET',
  HANDLE_BANK_LOGGIN_REDIRECT: 'moank/bank/HANDLE_BANK_LOGGIN_REDIRECT',
  RESTORE_BANK_LOGIN_SESSION: 'moank/bank/RESTORE_BANK_LOGIN_SESSION',
  BANK_AUTHENTICATION_REQUEST: 'moank/bank/BANK_AUTHENTICATION_REQUEST',
  BANK_AUTHENTICATION_SUCCESS: 'moank/bank/BANK_AUTHENTICATION_SUCCESS',
  BANK_AUTHENTICATION_FAILURE: 'moank/bank/BANK_AUTHENTICATION_FAILURE',
  BANK_LOGIN_SESSION_RESET: 'moank/bank/BANK_LOGIN_SESSION_RESET',
};

export const startBankLoginSession = (provider, parameters) => ({
  type: types.START_BANK_LOGIN_SESSION,
  provider,
  parameters,
});

export const startBankLoginSessionSuccess = ({ sessionId, scaApproach, link }) => ({
  type: types.START_BANK_LOGIN_SESSION_SUCCESS,
  sessionId,
  scaApproach,
  link,
});

export const startBankLoginSessionFailure = () => ({
  type: types.START_BANK_LOGIN_SESSION_FAILURE,
});

export const startBankLoginPolling = (sessionId) => ({
  type: types.START_BANK_LOGIN_POLLING,
  sessionId,
});

export const updateBankLoginSession = (status, message = '', token = '', image = '') => ({
  type: types.UPDATE_BANK_LOGIN_SESSION,
  status,
  message,
  token,
  image,
});

export const retrieveBankData = (provider, token) => ({
  type: types.RETRIEVE_BANK_DATA,
  provider,
  token,
});

export const retrieveBankDataSuccess = (accounts, reportId) => ({
  type: types.RETRIEVE_BANK_DATA_SUCCESS,
  accounts,
  reportId,
});

export const retrieveBankDataFailure = () => ({
  type: types.RETRIEVE_BANK_DATA_FAILURE,
});

export const bankReset = (message) => ({ type: types.BANK_RESET, message });

export const handleBankLoginRedirect = (
  sessionId,
  code,
  message,
  error,
  errorReason,
  trackingId,
) => ({
  type: types.HANDLE_BANK_LOGGIN_REDIRECT,
  sessionId,
  code,
  message,
  error,
  errorReason,
  trackingId,
});

export const restoreBankLoginSession = (sessionData) => ({
  type: types.RESTORE_BANK_LOGIN_SESSION,
  sessionData,
});

export const bankAuthentication = (payload) => ({
  type: types.BANK_AUTHENTICATION_REQUEST,
  sessionId: payload.sessionId,
});
export const bankAuthenticationSuccess = (payload) => ({
  type: types.BANK_AUTHENTICATION_SUCCESS,
  payload,
});
export const bankAuthenticationFailure = () => ({ type: types.BANK_AUTHENTICATION_FAILURE });

export const bankLoginSessionReset = () => ({ type: types.BANK_LOGIN_SESSION_RESET });
