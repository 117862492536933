import {
  all,
  call,
  put,
  takeEvery,
  select,
  takeLatest,
} from 'redux-saga/effects';
import { navigate } from 'gatsby';

import {
  types,
  downloadPublicDocumentSuccess,
  downloadPublicDocumentFailure,
  downloadAccountAgreementSuccess,
  downloadAccountAgreementFailure,
  downloadInvoiceSuccess,
  downloadInvoiceFailure,
  getDocumentInfoSuccess,
  getDocumentInfoFailure,
  signDocumentSuccess,
  signDocumentFailure,
  getAnnualTaxReportSuccess,
  getAnnualTaxReportFailure,
} from '@redux/actions/documents';
import { showModal, hideModal } from '@redux/actions/ui';
import { bankReset } from '@redux/actions/bank';
import { updateBankAccountPayin } from '@redux/actions/accounts';
import { invalidateCachedRequest } from '@redux/actions/cache';
import { cacheKeys } from '@redux/sagas/cache';
import {
  fetchInvoice,
  fetchAccountAgreement,
  fetchAnnualTaxReport,
} from '@apiClient/accounts';
import { fetchPublicDocumentUrl, getDocumentInfo } from '@apiClient/document';

import modalTypes from '@components/modals/types';

import bankIdSaga from '@redux/sagas/bankId';

import getUrl from '@utils/getUrl';

import urls from '@constants/urls';
import { bankIdRequests } from '@constants/bankId';
import helpTexts from '@constants/helpTexts';
import api from '@constants/api';

function* downloadPublicDocumentSaga(action) {
  const { product, documentType, utmSource } = action;
  try {
    const { downloadUrl } = yield call(fetchPublicDocumentUrl, product, documentType, utmSource);
    window.open(downloadUrl);
    yield put(downloadPublicDocumentSuccess());
  } catch (e) {
    yield put(showModal({ type: modalTypes.ERROR }));
    yield put(downloadPublicDocumentFailure());
  }
}

function* downloadAccountAgreementSaga(action) {
  const { accountId } = action;
  try {
    const { downloadUrl } = yield call(fetchAccountAgreement, accountId);
    global.location = downloadUrl;
    yield put(downloadAccountAgreementSuccess());
  } catch (e) {
    yield put(showModal({ type: modalTypes.ERROR }));
    yield put(downloadAccountAgreementFailure());
  }
}

function* downloadAnnualTaxReportSaga(action) {
  const { id } = action;
  try {
    const { downloadUrl } = yield call(fetchAnnualTaxReport, api.ANNUAL_TAX_REPORT(id));
    global.location = downloadUrl;
    yield put(getAnnualTaxReportSuccess());
  } catch (e) {
    yield put(getAnnualTaxReportFailure());
  }
}

function* downloadInvoiceSaga(action) {
  const { accountId, invoiceId } = action;
  try {
    const { downloadUrl } = yield call(fetchInvoice, accountId, invoiceId);
    global.location = downloadUrl;
    yield put(downloadInvoiceSuccess());
  } catch (e) {
    yield put(showModal({ type: modalTypes.ERROR }));
    yield put(downloadInvoiceFailure());
  }
}

function* getDocumentInfoSaga(action) {
  const documentTypes = yield select((state) => state.settings.constants.signableDocumentTypes);
  try {
    const response = yield call(getDocumentInfo, action.data);
    yield put(getDocumentInfoSuccess(response));
  } catch (e) {
    yield put(getDocumentInfoFailure());
    if (action.data.documentType === documentTypes.MANDATE) {
      yield put(showModal({
        type: modalTypes.ERROR,
        props: {
          title: 'Felaktig bankkonto',
          message: 'Du kan inte signera ett autogiromedgivande på detta bankkontot. Välj ett annat bankkonto.',
        },
      }));
    } else {
      yield put(showModal({ type: modalTypes.ERROR }));
    }
  }
}

function* signDocumentSaga({ payload }) {
  const documentTypes = yield select((state) => state.settings.constants.signableDocumentTypes);
  try {
    const data = yield call(bankIdSaga, {
      payload,
      action: bankIdRequests.SIGN_DOCUMENT,
    });

    yield put(signDocumentSuccess(data));
    yield put(hideModal());
    if (payload.documentType === documentTypes.MANDATE) {
      const { bankAccountId, accountId } = payload;
      yield put(updateBankAccountPayin(accountId, bankAccountId));
      yield put(invalidateCachedRequest(cacheKeys.ACCOUNT_GET_LOANS));
      yield put(invalidateCachedRequest(cacheKeys.USER_GET_ME));
      navigate(getUrl(urls.HOME.MY_LOANS.DETAILS, { loanId: accountId }));
    } else {
      navigate(getUrl(urls.HOME), { replace: true });
    }
    yield put(showModal({
      type: modalTypes.INFO,
      props: {
        title: 'Autogiro är anslutet till ditt lån.',
        helpText: helpTexts.MANDATE_SIGNED,
      },
    }));
    yield put(bankReset());
  } catch (e) {
    yield put(signDocumentFailure());
    if (e.response && e.response.statusCode === 401) {
      yield put(showModal({
        type: modalTypes.ERROR,
        props: {
          title: 'Signeringen misslyckades',
          message: e.response.body.message,
        },
      }));
    } else yield put(showModal({ type: modalTypes.ERROR }));
  }
}

export default function* documentsSaga() {
  yield all([
    takeEvery(types.DOWNLOAD_PUBLIC_DOCUMENT, downloadPublicDocumentSaga),
    takeEvery(types.DOWNLOAD_ACCOUNT_AGREEMENT, downloadAccountAgreementSaga),
    takeEvery(types.DOWNLOAD_ANNUAL_TAX_REPORT, downloadAnnualTaxReportSaga),
    takeEvery(types.DOWNLOAD_INVOICE, downloadInvoiceSaga),
    takeEvery(types.GET_DOCUMENT_INFO, getDocumentInfoSaga),
    takeLatest(types.SIGN_DOCUMENT_REQUEST, signDocumentSaga),
  ]);
}
