import { types } from '@redux/actions/ui';

// Reducer
const initialState = {
  modal: {},
  showBrowserNotice: true,
  showInformationNotice: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_MODAL: {
      return {
        ...state,
        modal: action.modal,
      };
    }
    case types.HIDE_MODAL: {
      return {
        ...state,
        modal: initialState.modal,
      };
    }
    case types.CLOSE_BROWSER_NOTICE: {
      return {
        ...state,
        showBrowserNotice: false,
      };
    }
    case types.CLOSE_INFORMATION_NOTICE: {
      return {
        ...state,
        showInformationNotice: false,
      };
    }
    default: {
      return state;
    }
  }
};
