import {
  all,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import {
  types,
  fetchSettings,
  fetchSettingsSuccess,
  fetchSettingsFailure,
} from '@redux/actions/settings';
import settingsFetch from '@apiClient/settings';

function* initSaga() {
  yield put(fetchSettings());
}

function* fetchDefaultSaga() {
  try {
    const settings = yield call(settingsFetch);
    yield put(fetchSettingsSuccess(settings));
  } catch (e) {
    yield put(fetchSettingsFailure());
  }
}

export default function* settingsSaga() {
  yield all([
    takeEvery(types.INIT, initSaga),
    takeEvery(types.FETCH_SETTINGS_REQUEST, fetchDefaultSaga),
  ]);
}
