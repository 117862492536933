export const types = {
  INIT: 'moank/settings/INIT',
  FETCH_SETTINGS_REQUEST: 'moank/settings/FETCH_SETTINGS_REQUEST',
  FETCH_SETTINGS_SUCCESS: 'moank/settings/FETCH_SETTINGS_SUCCESS',
  FETCH_SETTINGS_FAILURE: 'moank/settings/FETCH_SETTINGS_FAILURE',
};

export const init = () => ({ type: types.INIT });
export const fetchSettings = () => ({ type: types.FETCH_SETTINGS_REQUEST });
export const fetchSettingsSuccess = (settings) => ({
  type: types.FETCH_SETTINGS_SUCCESS,
  settings,
});
export const fetchSettingsFailure = () => ({ type: types.FETCH_SETTINGS_FAILURE });
