import {
  all,
  put,
  takeEvery,
} from 'redux-saga/effects';

import {
  types,
  validateField,
} from '@redux/actions/signup';
import validators from '@utils/validators';

function* validateFieldSaga(action) {
  const {
    field,
    value,
    min,
    max,
  } = action;
  if (typeof validators[field] === 'function') {
    let valid = validators[field](value);
    if (valid && min !== 0 && max !== 0) {
      valid = value >= min && value <= max;
    }
    yield put(validateField(field, valid));
  } else {
    yield put(validateField(field, validators.generics.isDefined(value)));
  }
}

export default function* signupSaga() {
  yield all([
    takeEvery(types.UPDATE_FIELD, validateFieldSaga),
  ]);
}
