import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import style from '@constants/style';

const SafelyWrappedLink = ({
  underlined,
  ...rest
// eslint-disable-next-line react/jsx-props-no-spreading
}) => (<Link {...rest} />);

SafelyWrappedLink.propTypes = {
  underlined: PropTypes.bool,
};

SafelyWrappedLink.defaultProps = {
  underlined: false,
};

export const StyledAnchor = styled.a`
  color: ${style.colours.primaryDark};
  cursor: pointer;

  text-decoration: ${({ underlined }) => (underlined ? 'underline' : 'none')};
  &:hover {
    text-decoration: underline;
  }

  background-color: transparent;
  padding: 0;
  border: none;
  font: inherit;
  display: inline-flex;
`;

const Anchor = ({
  onClick,
  to,
  href,
  underlined,
  target,
  children,
}) => {
  if (href) {
    return (
      <StyledAnchor href={href} onClick={onClick} underlined={underlined} target={target}>
        {children}
      </StyledAnchor>
    );
  }

  if (to) {
    return (
      <StyledAnchor to={to} as={SafelyWrappedLink} onClick={onClick} underlined={underlined}>
        {children}
      </StyledAnchor>
    );
  }

  if (onClick) {
    return (
      <StyledAnchor onClick={onClick} as="button" underlined={underlined}>
        {children}
      </StyledAnchor>
    );
  }

  throw new Error('An Anchor cannot be without "onClick", "href" and "to" prop');
};

Anchor.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  underlined: PropTypes.bool,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Anchor.defaultProps = {
  onClick: undefined,
  to: undefined,
  href: undefined,
  underlined: false,
  target: undefined,
};

export default Anchor;
