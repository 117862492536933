export const types = {
  GET_DOCUMENT_INFO: 'moank/documents/GET_DOCUMENT_INFO',
  GET_DOCUMENT_INFO_SUCCESS: 'moank/documents/GET_DOCUMENT_INFO_SUCCESS',
  GET_DOCUMENT_INFO_FAILURE: 'moank/documents/GET_DOCUMENT_INFO_FAILURE',
  DOWNLOAD_PUBLIC_DOCUMENT: 'moank/documents/DOWNLOAD_PUBLIC_DOCUMENT',
  DOWNLOAD_PUBLIC_DOCUMENT_FAILURE: 'moank/documents/DOWNLOAD_PUBLIC_DOCUMENT_FAILURE',
  DOWNLOAD_PUBLIC_DOCUMENT_SUCCESS: 'moank/documents/DOWNLOAD_PUBLIC_DOCUMENT_SUCCESS',
  DOWNLOAD_ACCOUNT_AGREEMENT: 'moank/documents/DOWNLOAD_ACCOUNT_AGREEMENT',
  DOWNLOAD_ACCOUNT_AGREEMENT_SUCCESS: 'moank/documents/DOWNLOAD_ACCOUNT_AGREEMENT_SUCCESS',
  DOWNLOAD_ACCOUNT_AGREEMENT_FAILURE: 'moank/documents/DOWNLOAD_ACCOUNT_AGREEMENT_FAILURE',
  DOWNLOAD_ANNUAL_TAX_REPORT: 'moank/documents/DOWNLOAD_ANNUAL_TAX_REPORT',
  DOWNLOAD_ANNUAL_TAX_REPORT_SUCCESS: 'moank/documents/DOWNLOAD_ANNUAL_TAX_REPORT_SUCCESS',
  DOWNLOAD_ANNUAL_TAX_REPORT_FAILURE: 'moank/documents/DOWNLOAD_ANNUAL_TAX_REPORT_FAILURE',
  DOWNLOAD_INVOICE: 'moank/documents/DOWNLOAD_INVOICE',
  DOWNLOAD_INVOICE_SUCCESS: 'moank/documents/DOWNLOAD_INVOICE_SUCCESS',
  DOWNLOAD_INVOICE_FAILURE: 'moank/documents/DOWNLOAD_INVOICE_FAILURE',
  SIGN_DOCUMENT_REQUEST: 'moank/signup/SIGN_DOCUMENT_REQUEST',
  SIGN_DOCUMENT_SUCCESS: 'moank/signup/SIGN_DOCUMENT_SUCCESS',
  SIGN_DOCUMENT_FAILURE: 'moank/signup/SIGN_DOCUMENT_FAILURE',
};

export const downloadPublicDocument = (product, documentType, utmSource) => ({
  type: types.DOWNLOAD_PUBLIC_DOCUMENT,
  product,
  documentType,
  utmSource,
});
export const downloadPublicDocumentFailure = () => ({
  type: types.DOWNLOAD_PUBLIC_DOCUMENT_FAILURE,
});
export const downloadPublicDocumentSuccess = () => ({
  type: types.DOWNLOAD_PUBLIC_DOCUMENT_SUCCESS,
});
export const downloadAccountAgreement = (accountId) => ({
  type: types.DOWNLOAD_ACCOUNT_AGREEMENT,
  accountId,
});
export const downloadAccountAgreementSuccess = () => ({
  type: types.DOWNLOAD_ACCOUNT_AGREEMENT_SUCCESS,
});
export const downloadAccountAgreementFailure = () => ({
  type: types.DOWNLOAD_ACCOUNT_AGREEMENT_FAILURE,
});
export const downloadInvoice = (accountId, invoiceId) => ({
  type: types.DOWNLOAD_INVOICE,
  accountId,
  invoiceId,
});
export const downloadInvoiceSuccess = () => ({
  type: types.DOWNLOAD_INVOICE_SUCCESS,
});
export const downloadInvoiceFailure = () => ({
  type: types.DOWNLOAD_INVOICE_FAILURE,
});

export const getDocumentInfo = (data) => ({
  type: types.GET_DOCUMENT_INFO,
  data,
});
export const getDocumentInfoFailure = () => ({
  type: types.GET_DOCUMENT_INFO_FAILURE,
});
export const getDocumentInfoSuccess = (data) => ({
  type: types.GET_DOCUMENT_INFO_SUCCESS,
  data,
});

export const signDocument = (payload) => ({
  type: types.SIGN_DOCUMENT_REQUEST,
  payload,
});
export const signDocumentSuccess = (payload) => ({
  type: types.SIGN_DOCUMENT_SUCCESS,
  payload,
});
export const signDocumentFailure = () => ({ type: types.SIGN_DOCUMENT_FAILURE });

export const getAnnualTaxReport = (id) => ({
  type: types.DOWNLOAD_ANNUAL_TAX_REPORT,
  id,
});

export const getAnnualTaxReportSuccess = () => ({
  type: types.DOWNLOAD_ANNUAL_TAX_REPORT_SUCCESS,
});

export const getAnnualTaxReportFailure = () => ({
  type: types.DOWNLOAD_ANNUAL_TAX_REPORT_FAILURE,
});
