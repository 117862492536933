import { navigate } from 'gatsby';
import {
  all,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';

import {
  types,
  updateBankAccountPayinSuccess,
  updateBankAccountPayinFailure,
  getLoansSuccess,
  getLoansFailure,
  getInsurancesSuccess,
  getInsurancesFailure,
} from '@redux/actions/accounts';
import { showModal } from '@redux/actions/ui';
import { logout } from '@redux/actions/auth';
import {
  updateBankAccountPayin,
  getLoans,
  getInsurances,
} from '@apiClient/accounts';
import { invalidateCachedRequest } from '@redux/actions/cache';
import cacheSaga, { cacheKeys } from '@redux/sagas/cache';
import modalTypes from '@components/modals/types';
import getUrl from '@utils/getUrl';
import urls from '@constants/urls';
import helpTexts from '@constants/helpTexts';

function* getLoansSaga() {
  try {
    const loans = yield call(cacheSaga, cacheKeys.ACCOUNT_GET_LOANS, getLoans);
    yield put(getLoansSuccess(loans));
  } catch (e) {
    if (e.response && e.response.statusCode === 401) {
      yield put(logout());
    } else {
      yield put(showModal({ type: modalTypes.ERROR }));
      yield put(getLoansFailure());
    }
  }
}

function* getInsurancesSaga() {
  try {
    const insurances = yield call(cacheSaga, cacheKeys.ACCOUNT_GET_INSURANCES, getInsurances);
    yield put(getInsurancesSuccess(insurances));
  } catch (e) {
    if (e.response && e.response.statusCode === 401) {
      yield put(logout());
    } else {
      yield put(showModal({ type: modalTypes.ERROR }));
      yield put(getInsurancesFailure());
    }
  }
}

function* updateBankAccountPayinSaga(action) {
  const { accountId, newBankAccountIdPayin } = action.payload;

  try {
    const updatedAccount = yield call(updateBankAccountPayin, accountId, newBankAccountIdPayin);
    yield put(updateBankAccountPayinSuccess(accountId, updatedAccount.bankAccountIdPayin));
    yield put(invalidateCachedRequest(cacheKeys.ACCOUNT_GET_LOANS));
    if (!newBankAccountIdPayin) {
      yield put(showModal({
        type: modalTypes.INFO,
        props: {
          title: 'Koppla bort autogiro',
          helpText: helpTexts.AUTOGIRO_DISCONNECTED,
        },
      }));
    }
    const redirectUrl = getUrl(urls.HOME.MY_LOANS.DETAILS, { loanId: accountId });
    if (global.location.pathname !== redirectUrl) {
      navigate(redirectUrl);
    }
  } catch (e) {
    yield put(showModal({ type: modalTypes.ERROR }));
    yield put(updateBankAccountPayinFailure(accountId));
  }
}

export default function* accountSaga() {
  yield all([
    takeEvery(types.GET_LOANS_REQUEST, getLoansSaga),
    takeEvery(types.GET_INSURANCES_REQUEST, getInsurancesSaga),
    takeEvery(types.UPDATE_BANK_ACCOUNT_PAYIN, updateBankAccountPayinSaga),
  ]);
}
