import { types } from '@redux/actions/documents';
import { types as authTypes } from '@redux/actions/auth';

const initialState = {
  loading: false,
  documentUrl: undefined,
  documentType: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_DOCUMENT_INFO:
      return {
        ...initialState,
        loading: true,
      };
    case types.GET_DOCUMENT_INFO_SUCCESS:
      return {
        documentUrl: action.data.documentUrl,
        documentType: action.data.documentType,
        loading: false,
      };
    case types.GET_DOCUMENT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case authTypes.LOGOUT: {
      return initialState;
    }
    default: {
      return state;
    }
  }
};
