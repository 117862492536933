import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';

import { types } from '@redux/actions/signup';

const initialState = {
  bank: { value: '', valid: null },
  bankAccountId: { value: '', valid: null },
  loading: false,
  selectedBankAccount: { value: '', valid: null },
  processingError: false,
};

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_FIELD:
      return set(cloneDeep(state), `${action.field}.value`, action.value);

    case types.VALIDATE_FIELD:
      return set(cloneDeep(state), `${action.field}.valid`, action.valid);
    default:
      return state;
  }
};

export default signupReducer;
