export default {
  LOAN_PROTECTION: 'LOAN_PROTECTION',
  LOAN_PROTECTION_CALCULATION: 'LOAN_PROTECTION_CALCULATION',
  CRITICAL_DISEASES: 'CRITICAL_DISEASES',
  MANDATE_SIGNED: 'MANDATE_SIGNED',
  PAYMENT_REMARKS: 'PAYMENT_REMARKS',
  LOAN_PROTECTION_INFO: 'LOAN_PROTECTION_INFO',
  BANK_DATA_AGGREGATION: 'BANK_DATA_AGGREGATION',
  AUTOGIRO_ACTIVE: 'AUTOGIRO_ACTIVE',
  AUTOGIRO_NOT_ACTIVE: 'AUTOGIRO_NOT_ACTIVE',
  AUTOGIRO_DISCONNECTED: 'AUTOGIRO_DISCONNECTED',
  LATE: 'LATE',
  BANK_ACCOUNT_CONNECTION: 'BANK_ACCOUNT_CONNECTION',
  BANK_ACCOUNT_CONNECTION_NO_SIGNABLE: 'BANK_ACCOUNT_CONNECTION_NO_SIGNABLE',
};
