import responseHandler from '@utils/responseHandler';
import requestBuilder from '@utils/requestBuilder';
import { api, requestsMethods } from '@constants';

const settingsFetch = responseHandler(async () => requestBuilder({
  method: requestsMethods.GET,
  url: api.SETTINGS_PUBLIC,
}));

export default settingsFetch;
