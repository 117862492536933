import numberFormat from '@utils/numberFormat';

export default {
  CUSTOMER_SERVICE_NUMBER: '08-520\xa0059\xa055',
  CUSTOMER_SERVICE_EMAIL: 'info@moank.se',

  REMINDER_FEE_MOANK_LOAN: '60\xa0kr',

  LOAN_PROTECTION_MAX_TIME_COVERAGE: numberFormat(12, 0, { unit: 'månader' }),
  LOAN_PROTECTION_PREMIUM: '6,5\xa0%',
};
