import {
  all,
  call,
  put,
  takeEvery,
} from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';

import { logout } from '@redux/actions/auth';
import {
  types,
  fetchUserSuccess,
  fetchUserFailure,
  createBudgetSuccess,
  createBudgetFailure,
} from '@redux/actions/user';
import {
  userFetchSelf,
  userCreateBudget,
} from '@apiClient/user';
import cacheSaga, { cacheKeys } from '@redux/sagas/cache';

function* fetchUserSaga() {
  try {
    const user = yield call(cacheSaga, cacheKeys.USER_GET_ME, userFetchSelf);
    Sentry.setUser({ email: user.email, id: user.id });
    yield put(fetchUserSuccess(user));
  } catch (e) {
    if (e.response.statusCode === 401) {
      yield put(logout());
    }
    yield put(fetchUserFailure(e));
  }
}

function* createBudgetSaga(action) {
  const { userId } = action;
  try {
    const { sheetUrl } = yield call(userCreateBudget, userId);
    yield put(createBudgetSuccess({ sheetUrl }));
  } catch (e) {
    yield put(createBudgetFailure());
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(types.FETCH_USER_REQUEST, fetchUserSaga),
    takeEvery(types.CREATE_BUDGET_REQUEST, createBudgetSaga),
  ]);
}
