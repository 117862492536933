import { types } from '@redux/actions/accounts';
import { types as authTypes } from '@redux/actions/auth';

const initialState = {
  loans: {},
  insurances: {},
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.GET_LOANS_REQUEST:
    case types.GET_INSURANCES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.GET_LOANS_FAILURE:
    case types.GET_INSURANCES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.GET_LOANS_SUCCESS: {
      return {
        ...state,
        loans: action.loans.reduce((agg, accountInfo) => ({
          ...agg,
          [accountInfo.id]: accountInfo,
        }), {}),
        loading: false,
      };
    }
    case types.GET_INSURANCES_SUCCESS: {
      return {
        ...state,
        insurances: action.insurances.reduce((agg, accountInfo) => ({
          ...agg,
          [accountInfo.id]: accountInfo,
        }), {}),
        loading: false,
      };
    }
    case types.UPDATE_BANK_ACCOUNT_PAYIN: {
      return {
        ...state,
        loans: {
          ...state.loans,
          [action.payload.accountId]: {
            ...state.loans[action.payload.accountId],
            bankAccountLoading: true,
          },
        },
      };
    }
    case types.UPDATE_BANK_ACCOUNT_PAYIN_SUCCESS: {
      return {
        ...state,
        loans: {
          ...state.loans,
          [action.payload.accountId]: {
            ...state.loans[action.payload.accountId],
            bankAccountIdPayin: action.payload.bankAccountIdPayin,
            bankAccountLoading: false,
          },
        },
      };
    }
    case types.UPDATE_BANK_ACCOUNT_PAYIN_FAILURE: {
      return {
        ...state,
        loans: {
          ...state.loans,
          [action.payload.accountId]: {
            ...state.loans[action.payload.accountId],
            bankAccountLoading: false,
          },
        },
      };
    }
    case authTypes.LOGOUT:
      return initialState;
    default: {
      return state;
    }
  }
};
