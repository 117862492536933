const routes = {
  _baseUrl: '/',
  BANK_LOGIN_CALLBACK: { _baseUrl: 'bank-login-callback' },
  LANDING: { _baseUrl: '' },
  HOME: {
    _baseUrl: 'mina-sidor',
    ACCOUNT_CREDIT: {
      _baseUrl: 'kontokredit',
      PAYMENTS: { _baseUrl: 'betalningar' },
      TRANSACTIONS: { _baseUrl: 'handelser' },
    },
    MOANK_LOAN: {
      _baseUrl: 'moank-lanet',
      PAYMENTS: { _baseUrl: 'betalningar' },
      TRANSACTIONS: { _baseUrl: 'handelser' },
    },
    AUTOGIRO: {
      _baseUrl: 'autogiro',
      MANDATE: { _baseUrl: ':accountId/medgivande' },
      VALID_BANK_ACCOUNT: { _baseUrl: ':accountId/valj-bankkonto' },
      CONNECT_BANK: { _baseUrl: ':accountId/ansluta-bank' },
      SIGN_MANDATE: { _baseUrl: ':accountId/signera/:bankAccountId' },
    },
    MY_LOANS: {
      _baseUrl: 'mina-lan',
      DETAILS: { _baseUrl: ':loanId/detaljer' },
      TRANSACTIONS: { _baseUrl: ':loanId/transaktioner' },
      PAYMENT_PLAN: { _baseUrl: ':loanId/betalningsplan' },
    },
    MY_INSURANCES: {
      _baseUrl: 'mina-forsakringar',
      DETAILS: { _baseUrl: ':insuranceId/detaljer' },
      TRANSACTIONS: { _baseUrl: ':insuranceId/transaktioner' },
    },
  },
};

export default routes;
