import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useScript from 'react-script-hook';
import { useLocation } from '@reach/router';

const url = 'https://moank.liveagent.se/scripts/track.js';
const PAGE_TO_EXCLUDE_BUTTON = '/mina-sidor';
export const LiveAgentContext = React.createContext();

const LiveAgent = ({
  scriptId,
  buttonId,
  children,
}) => {
  const [loading, error] = useScript({
    src: url,
    checkForExisting: true,
    id: scriptId,
    async: true,
  });

  const [buttonRef, setButtonRef] = useState();
  const { pathname } = useLocation();

  useEffect(() => {
    if (loading || error) return () => {};

    const interval = setInterval(() => {
      const scriptTag = global.document.querySelector(`script[src="${url}"]`);
      if (global.LiveAgent && scriptTag) {
        clearInterval(interval);
        const button = global.LiveAgent.createButton(buttonId, scriptTag);
        setButtonRef(button);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [loading, error, buttonId]);

  useEffect(() => {
    if (buttonRef) {
      if (pathname.startsWith(PAGE_TO_EXCLUDE_BUTTON)) buttonRef.buttonDiv.style.display = 'none';
      else buttonRef.buttonDiv.style.display = 'block';
    }
  }, [buttonRef, pathname]);

  return (
    <LiveAgentContext.Provider value={buttonRef}>
      {children}
    </LiveAgentContext.Provider>
  );
};

LiveAgent.propTypes = {
  scriptId: PropTypes.string.isRequired,
  buttonId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LiveAgent;
