import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import createStore from '@redux/createStore';
import { init } from '@redux/actions/settings';

import PageErrorBoundary from '@components/errorBoundaries/PageErrorBoundary';
import { MediaQueryProvider } from '@components/utils/MediaQuery';

import moankTheme from '@constants/moankTheme';

const Root = ({ element }) => {
  const store = createStore();

  useEffect(() => {
    store.dispatch(init());
    // NOTE: Temprorary disable google tranlsate. Issue: https://github.com/facebook/react/issues/11538
    if (typeof window !== 'undefined') window.document.body.classList.add('notranslate');
  }, [store]);

  return (
    <PageErrorBoundary>
      <Provider store={store}>
        <ThemeProvider theme={moankTheme}>
          <MediaQueryProvider>
            {element}
          </MediaQueryProvider>
        </ThemeProvider>
      </Provider>
    </PageErrorBoundary>
  );
};

Root.propTypes = {
  element: PropTypes.node.isRequired,
};

const SafeWrapper = ({ element }) => <Root element={element} />;
SafeWrapper.propTypes = {
  element: PropTypes.node.isRequired,
};

export default SafeWrapper;
