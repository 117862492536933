/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import style from '@constants/style';
import styled from 'styled-components';

import { Consumer } from './MediaQueryContext';

const orderedScreenTypes = Object.entries(style.minSizes)
  .sort((a, b) => a[1] - b[1]).map((el) => el[0]);

const StyledMediaQuery = styled('div')`
  ${(props) => props.availableSizes.map((size) => style.media[size]`
    display: ${size === props.size ? 'contents' : 'none'};
  `)}
`;

class MediaQuery extends Component {
  constructor() {
    super();
    this.state = { isClient: false };
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ isClient: true });
  }

  render() {
    const { isClient } = this.state;
    if (!isClient) {
      return (
        <>
          {orderedScreenTypes.map((size) => (this.props[size] ? <StyledMediaQuery key={size} size={size} availableSizes={Object.keys(this.props)}>{this.props[size]}</StyledMediaQuery> : null))}
        </>
      );
    }

    return (
      <Consumer>
        {(screenType) => {
          const screenTypeIndex = orderedScreenTypes.findIndex((e) => e === screenType);
          const compatibleSizes = orderedScreenTypes.slice(0, screenTypeIndex + 1);
          const bestDefinedProp = compatibleSizes.reverse()
            .find((e) => this.props[e] !== undefined);
          return bestDefinedProp ? this.props[bestDefinedProp] : null;
        }}
      </Consumer>
    );
  }
}

export default MediaQuery;
