import { parsePhoneNumberFromString } from 'libphonenumber-js/core';
import metadata from '../utils/metadata.custom.json';
import info from './info';

const getInternationalNumber = (phoneNumber) => {
  const parsed = parsePhoneNumberFromString(phoneNumber, 'SE', metadata);
  return parsed && parsed.number;
};

const externals = {
  CALL_TO_MOANK: `tel:${getInternationalNumber(info.CUSTOMER_SERVICE_NUMBER)}`,
  FACEBOOK_MOANK: 'https://www.facebook.com/Moank-1480191712269135/',
  LINKEDIN_MOANK: 'https://www.linkedin.com/company/moank',
  MAIL_TO_MOANK: `mailto:${info.CUSTOMER_SERVICE_EMAIL}`,
  INSTAGRAM_MOANK: 'https://www.instagram.com/moank.se/',
  LIVEAGENT_COOKIES_POLICY: 'https://support.liveagent.com/709382-Cookies-used-by-LiveAgent',
  GOOGLE_POLICY: 'https://policies.google.com/privacy?hl=en#infodelete',
  HOTJAR_COOKIES_POLICY: 'https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookie-Information',
  WEBSITE_ABOUT_US: 'https://moank.se/om-oss',
  WEBSITE_CONTACT: 'https://moank.se/kontakt',
  WEBSITE: 'https://moank.se',
  SIGNUP: 'https://signup.moank.se/lan/signup/belopp',
};

export default externals;
