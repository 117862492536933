import cookies from 'js-cookie';

export const cookiesNames = {
  ANSWERED_COOKIE_PREFERENCES: 'answeredCookiePreferences',
  EXPERIENCE_COOKIES_ALLOWED: 'experienceCookiesAllowed',
  STATISTICS_COOKIES_ALLOWED: 'statisticsCookiesAllowed',
  MARKETTING_COOKIES_ALLOWED: 'markettingCookiesAllowed',
  SHOW_UC_PROVIDER: 'showUCProvider',

};

export const setCookie = (name, value, expiresIn = 365) => {
  try {
    cookies.set(name, value, { expires: expiresIn, secure: process.env.NODE_ENV === 'production', sameSite: 'Lax' });
  } catch (e) {
    // Ignore error if cookies are disabled
  }
};

export const getCookie = (name) => {
  const value = cookies.get(name);

  if (value === 'false') return false;
  if (value === 'true') return true;
  return undefined;
};
